import React from 'react';
import './App.css';
import { Button, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedReinins: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
            selectedTypes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            exes: false
        };
    }

    componentDidMount () {

        this.setState(JSON.parse(localStorage.getItem('state')));
    };

    componentDidUpdate (prevProp, prevState, snapshot) {

        localStorage.setItem('state',JSON.stringify(this.state));
    };

    render() {


        let reinins = [
            {
                id: 0,
                name: "Інтуїт Сенсорик",
                value: [1, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0],
                plus: "Інтуїт",
                minus: "Сенсорик",
                x: 1
            },
            {
                id: 1,
                name: "Логік Етик",
                value: [1, 0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1],
                plus: "Логік",
                minus: "Етик",
                x: 2
            }, {
                id: 2,
                name: "Статик Динамік",
                value: [1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0],
                plus: "Статик",
                minus: "Динамік",
                x: 3
            }, {
                id: 3,
                name: "Екстраверт Інтроверт",
                value: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
                plus: "Екстраверт",
                minus: "Інтроверт",
                x: 4
            }, {
                id: 4,
                name: "Демократ Аристократ",
                value: [1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0],
                plus: "Демократ",
                minus: "Аристократ",
                x: 12
            }, {
                id: 5,
                name: "Розважливий Рішучий",
                value: [1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1],
                plus: "Розважливий",
                minus: "Рішучий",
                x: 13
            }, {
                id: 6,
                name: "Безпечний Передбачливий",
                value: [1, 1, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1],
                plus: "Безпечний",
                minus: "Передбачливий",
                x: 14
            }, {
                id: 7,
                name: "Веселий Серьозний",
                value: [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0],
                plus: "Веселий",
                minus: "Серьозний",
                x: 23

            }, {
                id: 8,
                name: "Поступливий Упертий",
                value: [1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0],
                plus: "Поступливий",
                minus: "Упертий",
                x: 24
            }, {
                id: 9,
                name: "Ірраціонал Раціонал",
                value: [1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 1, 1],
                plus: "Ірраціонал",
                minus: "Раціонал",
                x: 34
            }, {
                id: 10,
                name: "Квестім Деклатім",
                value: [1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1],
                plus: "Квестім",
                minus: "Деклатім",
                x: 123
            }, {
                id: 11,
                name: "Позитивіст Негативіст",
                value: [1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1],
                plus: "Позитивіст",
                minus: "Негативіст",
                x: 124
            }, {
                id: 12,
                name: "Тактик Стратег",
                value: [1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0],
                plus: "Тактик",
                minus: "Стратег",
                x: 134
            }, {
                id: 13,
                name: "Конструктивіст Емотивіст",
                value: [1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1],
                plus: "Конструктивіст",
                minus: "Емотивіст",
                x: 234
            }, {
                id: 14,
                name: "Процесор Результатер",
                value: [1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0],
                plus: "Процесор",
                minus: "Результатер",
                x: 1234
            }];

        let types = [
            {id: 0, code: "ІЛЕ", name: "Шукач"},
            {id: 1, code: "СЕІ", name: "Посередник"},
            {id: 2, code: "ЕСЕ", name: "Ентузіаст"},
            {id: 3, code: "ЛІІ", name: "Аналітик"},
            {id: 4, code: "ЕІЕ", name: "Наставник"},
            {id: 5, code: "ЛСІ", name: "Інспектор"},
            {id: 6, code: "СЛЕ", name: "Маршал"},
            {id: 7, code: "ІЕІ", name: "Лірик"},
            {id: 8, code: "СЕЕ", name: "Політик"},
            {id: 9, code: "ІЛІ", name: "Критик"},
            {id: 10, code: "ЛІЕ", name: "Підприємець"},
            {id: 11, code: "ЕСІ", name: "Хранитель"},
            {id: 12, code: "ЛСЕ", name: "Адміністратор"},
            {id: 13, code: "ЕІІ", name: "Гуманіст"},
            {id: 14, code: "ІЕЕ", name: "Порадник"},
            {id: 15, code: "СЛІ", name: "Майстер"}
        ];


        let descriptionRender = <h5>Порівняння ознак типів, підбір типу за ознаками.</h5>;
        let legendReinins = (<p className="small text-secondary">{types.map(t => t.code + " - " + t.name + "; ")}</p>);

        let exesCheckmark = <form>
            <div className="form-check">
                <input type="checkbox" defaultChecked={this.state.exes} className="form-check-input" id="exampleCheck1"
                       onChange={() => {  this.setState({exes: !this.state.exes}); }   }/>
                <label className="form-check-label" htmlFor="exampleCheck1">
                    <small>Показати зв'зки</small>
                </label>
            </div>
        </form>;

        // CLEAR FEATURE

        let clearReinins = () => {
            this.setState({selectedReinins: this.state.selectedReinins.map(r => null)})
        };

        let clearTypes = () => {
            this.setState({selectedTypes: this.state.selectedTypes.map(t => 0)})
        };

        let clearReininsButton  = <Button size="sm" variant="light" className="mx-2" onClick={() => clearReinins()}>X</Button>;
        let clearTypesButton = <Button size="sm" variant="light" className="mx-2" onClick={() => clearTypes()}>X</Button>;

        /*
        let clearButtons = <div className="d-flex align-items-center small my-2">
            <span>Очистити:</span>
            <Button size="sm" variant="outline-info" className="mx-2" onClick={() => clearReinins()}>Ознаки</Button>
            <Button size="sm" variant="outline-info" onClick={() => clearTypes()}>Типи</Button>
        </div>;
        */


        // === REININS ===

        let selectReinin = (id, state) => {
            let arr = this.state.selectedReinins;
            arr[id] = (arr[id] === null ? state : arr[id] === state ? null : state);

            this.setState({selectedReinins: arr});
        };

        let selectedReininsCount = this.state.selectedReinins.filter(r => r !== null).length;

        let normalButton = "light";
        let selectedButton = "secondary";

        let dichotomyListRender = <Table className="reininsTable" size="sm">
            <thead>
            <tr>
                <th colSpan={this.state.exes ? 3 : 2}>Дихотомії {clearReininsButton}</th>
            </tr>
            </thead>
            <tbody>{reinins.map((r, idr) => {

                let pluses = this.state.selectedTypes
                    .map((t, idt) => t > 0 ? (r.value[idt] === 1 ? types[idt].name : null) : null)
                    .filter(v => v !== null);

                let minuses = this.state.selectedTypes
                    .map((t, idt) => t > 0 ? (r.value[idt] === 0 ? types[idt].name : null) : null)
                    .filter(v => v !== null);

                let pmStyle = "small text-secondary mx-1";

                let exesCell = this.state.exes ? <td className="small pt-2"><span className="text-muted" >x{r.x}</span></td>  : null;

                return (
                    <tr key={r.id}
                        className={r.id === 3 || r.id === 14 ? "border-bottom border-dark" : ""}>
                        {exesCell}
                        <td className="w-50"><Button
                            variant={this.state.selectedReinins[r.id] === 1 ? selectedButton : normalButton}
                            size="sm"
                            onClick={() => selectReinin(r.id, 1)}>
                            {r.plus}</Button>
                            {pluses.length > 0 ?
                                <span className="small">
                                    {pluses.map(v => <span key={v} className={pmStyle}>{v}</span>)}
                                    </span>
                                : null}
                        </td>
                        <td className="w-50"><Button
                            variant={this.state.selectedReinins[r.id] === 0 ? selectedButton : normalButton}
                            size="sm"
                            onClick={() => selectReinin(r.id, 0)}>
                            {r.minus}</Button>
                            {minuses.length > 0 ?
                                <span className="small">
                                    {minuses.map(v => <span key={v} className={pmStyle}>{v}</span>)}
                                </span>
                                : null}
                        </td>
                    </tr>);
            })}</tbody>
        </Table>;



        // === TYPES ===

        let highlightTypes = types.map((t, typeIdx) => {

            let equal = [];
            let different = [];
            this.state.selectedReinins.forEach((r, reinIdx) => {
                if (r !== null)
                    if (reinins[reinIdx].value[typeIdx] === r)
                        equal.push({reinin: reinIdx, value: r});
                    else
                        different.push({reinin: reinIdx, value: r});
            });

            return {equal, different};
        });

        // console.log("sel-rein", this.state.selectedReinins);
        // console.log("highlightTypes", highlightTypes);

        let selectType = (id) => {
            let arr = this.state.selectedTypes;
            if (arr[id] === 0 && arr.filter(v => v > 0).length === 2) {
                // selection limit
            }
            else {
                arr[id] = !arr[id];
                this.setState({selectedTypes: arr});
            }
        };

        //console.log("selectedTypes");

        function comparePoints(a, b) {
            if (highlightTypes[a.id].equal.length > highlightTypes[b.id].equal.length) {
                return -1;
            }
            /*
            if ( highlightTypes[a.id].equal.length < highlightTypes[b.id].equal.length ){
                return 1;
            }*/
            return 0;
        }

        let clonedArray = JSON.parse(JSON.stringify(types));
        clonedArray.sort(comparePoints);

        let typesListRender = <Table className="mx-3 typesTable" size="sm">
            <thead>
            <tr>
                <th>Типи {clearTypesButton}</th>
            </tr>
            </thead>
            <tbody>{
                clonedArray.map((t, idx) => {

                    let topValue = Math.max(...(highlightTypes.map(ht => ht.equal.length)));
                    let boltTop = highlightTypes[t.id].equal.length === topValue && topValue > 0;
                    let points = highlightTypes[t.id].equal.length;
                    let pointText = points > 0 ? " (" + points + "/" + selectedReininsCount + ")" : "";
                    let minuses = highlightTypes[t.id].different
                        .map(d => d.value === 0 ? reinins[d.reinin].plus : reinins[d.reinin].minus);
                    let minusesRender = <span><span className="small mx-1">{
                        minuses.map(d => <span key={d} className="mx-1 small text-secondary">{d}</span>)
                    }</span></span>;

                    let name = <span><span>{t.name}</span> <span className="small">{t.code}</span></span>;

                    return <tr key={idx} className={idx === 15 ? "border-bottom border-dark" : ""}>
                        <td><Button
                            variant={this.state.selectedTypes[t.id] ? selectedButton : normalButton}
                            size="sm"
                            onClick={() => selectType(t.id)}>
                            {boltTop ? <b>{name}</b> : name}
                            {pointText}
                        </Button>
                            {points > 0 && minuses.length > 0 ? minusesRender : null}
                        </td>
                    </tr>
                })
            }</tbody>
        </Table>;


        let pageRender = (
            <div>
                {descriptionRender}
                <div className="d-flex my-3 align-items-baseline">
                    {dichotomyListRender}
                    {typesListRender}
                </div>
                {exesCheckmark}
            </div>
        );

        return (pageRender);
    };

}

export default Content;
